<template>
  <v-container class="container-form">
    <BackArrow class="mt-8" />
    <v-subheader class="title mt-n4 mb-4 pa-0 black--text">
      Acompanhamento de Encomendas
    </v-subheader>

    <v-form @submit.prevent>
      <v-card class="mb-15">
        <v-card-title class="primary white--text d-flex justify-space-between">
          <span>Movimentação</span>
          <span>Campos Obrigatórios *</span>
        </v-card-title>

        <v-card-text class="pt-7 px-7">
          <v-row dense>
            <v-col cols="12" sm="12">
              <span class="primary--text">Código de Barras</span>
              <v-text-field
                v-model="search"
                required
                append-icon="mdi-barcode"
                autofocus
                v-mask-number
                @keyup="search ? searchData() : null"
                :loading="loading"
                placeholder="Informe o código de barras"
                class="mt-n1"
              />
            </v-col>
            <v-col cols="12" sm="12" md="5">
              <span class="primary--text">Motorista Responsável</span>
              <v-autocomplete
                v-model="id_motorista"
                required
                item-text="nome"
                item-value="id"
                :items="motoristas"
                placeholder="Informe o motorista responsável"
                class="mt-1 custom-solo-selection"
                solo
              />
            </v-col>
            <v-col
              cols="12"
              class="text-center"
              v-if="data.length === 0 && !loading"
            >
              <span class="title black--text">
                Código de barras inválido ou inexistente!
              </span>
            </v-col>
            <v-col
              cols="12"
              class="d-flex justify-center align-center"
              v-else-if="data.length === 0 && loading"
            >
              <v-progress-circular indeterminate color="primary" :size="35" />
            </v-col>
            <v-col cols="12" v-else>
              <v-timeline>
                <v-timeline-item
                  v-for="(d, i) in data"
                  :key="i"
                  :color="
                    d.status == 'ENVIADO'
                      ? 'accent'
                      : d.status == 'ENTREGUE'
                      ? 'success'
                      : 'primary'
                  "
                  :icon="
                    d.status == 'ENVIADO'
                      ? 'mdi-truck-fast'
                      : d.status == 'ENTREGUE'
                      ? 'mdi-truck-check'
                      : 'mdi-warehouse'
                  "
                  fill-dot
                >
                  <template v-slot:opposite>
                    <span
                      :class="`subtitle-1 font-weight-bold ${
                        d.status == 'ENVIADO'
                          ? 'accent'
                          : d.status == 'ENTREGUE'
                          ? 'success'
                          : 'primary'
                      }--text`"
                      >{{ d.data_hora }}</span
                    >
                  </template>
                  <div class="py-4">
                    <div
                      :class="`subtitle-1 font-weight-light mb-1 ${
                        d.status == 'ENVIADO'
                          ? 'accent'
                          : d.status == 'ENTREGUE'
                          ? 'success'
                          : 'primary'
                      }--text`"
                    >
                      <b class="text-capitalize">
                        Status:
                        {{
                          agencia.id_localidade ==
                            data[0].id_localidade_origem && i == 0
                            ? "Despachado"
                            : d.status.toLowerCase()
                        }}
                      </b>
                    </div>

                    <div
                      class="body-2 font-weight-light text-capitalize black--text"
                    >
                      <div>
                        <b>Origem:</b>
                        {{ cityCase(d.origem) }}
                      </div>
                      <div>
                        <b>Destino:</b>
                        {{ cityCase(d.destino) }}
                      </div>
                      <div v-if="d.status != 'ENTREGUE'">
                        <b>
                          Agência:
                          <span
                            :style="`color:${
                              d.status == 'ENVIADO'
                                ? '#ccae62'
                                : d.status == 'ENTREGUE'
                                ? 'green'
                                : '#2054AF'
                            }`"
                          >
                            {{ d.localidade.toLowerCase() }} -
                            {{ d.estado_localidade }}
                          </span>
                        </b>
                      </div>
                      <div v-if="d.status == 'ENTREGUE'">
                        <b style="color: green">
                          Entregue
                          {{
                            d.entregue_por == 1
                              ? "pelo motorista"
                              : "pela agência"
                          }}
                          ao cliente
                        </b>
                      </div>
                    </div>
                  </div>
                </v-timeline-item>
              </v-timeline>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-footer color="primary" class="rounded-t footer-form py-0" absolute>
        <v-btn
          v-if="
            data.length > 0 &&
            !data[0].jafoirecebido &&
            !data[0].enviou &&
            !data[0].entregue &&
            (!data[0].cancelada || data[0].cancelada == 0)
          "
          depressed
          dark
          text
          @click="
            movementOrder({
              id_encomenda: data[0].cod_encomenda,
              id_operador: $store.getters.getOperador,
              id_agencia: agencia.id,
              status: 'RECEBIDO',
            })
          "
          class="my-2"
        >
          Atualizar Status
        </v-btn>
        <div class="flex-grow-1"></div>
        <v-btn
          v-if="
            data.length > 0 &&
            agencia.id_localidade != data[0].id_localidade_destino &&
            !data[0].enviou &&
            data[0].jafoirecebido &&
            (!data[0].cancelada ||
              data[0].cancelada == 0 ||
              data[0].cancelada == 0)
          "
          text
          depressed
          dark
          @click="
            sendPacket({
              id_encomenda: data[0].cod_encomenda,
              id_operador: $store.getters.getOperador,
              id_agencia: agencia.id,
              status: 'ENVIADO',
            })
          "
          class="my-2"
        >
          Enviar Encomenda
        </v-btn>
        <v-btn
          v-if="
            data.length > 0 &&
            agencia.id_localidade == data[0].id_localidade_destino &&
            !data[0].entregue &&
            data[data.length - 1].status == 'RECEBIDO' &&
            (!data[0].cancelada || data[0].cancelada == 0)
          "
          text
          depressed
          dark
          @click="
            [
              data[0].cobranca == 2
                ? (show = true)
                : finalizeOrder(
                    {
                      id_encomenda: data[0].cod_encomenda,
                      id_operador: $store.getters.getOperador,
                      id_agencia: agencia.id,
                      status: 'ENTREGUE',
                    },
                    true
                  ),
              (cod_encomenda = data[0].cod_encomenda),
            ]
          "
          class="my-2"
        >
          Finalizar
        </v-btn>
      </v-footer>
    </v-form>

    <v-dialog v-model="show" persistent width="400">
      <v-card class="overflow-hidden">
        <v-card-title class="primary white--text">
          Confirmar Encomenda
        </v-card-title>
        <v-card-text class="pt-4">
          <span class="title black--text">
            Essa encomenda está sendo paga neste momento?
          </span>
        </v-card-text>
        <v-card-actions class="mx-2 pt-0">
          <v-row>
            <v-col cols="12" sm="6">
              <v-btn
                @click="formaPagamentoShow = true"
                color="primary"
                block
                dark
              >
                SIM
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6">
              <v-btn
                @click="
                  finalizeOrder(
                    {
                      id_encomenda: cod_encomenda,
                      id_operador: $store.getters.getOperador,
                      id_agencia: agencia.id,
                      status: 'ENTREGUE',
                    },
                    null
                  )
                "
                color="green darken-2"
                block
                dark
              >
                NÃO
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="formaPagamentoShow" width="400">
      <v-form @submit.prevent ref="form" v-model="valid" lazy-validation>
        <v-card class="overflow-hidden">
          <v-card-title class="primary white--text">
            Confirmar Encomenda
          </v-card-title>
          <v-card-text class="pt-4">
            <v-row>
              <v-col cols="12">
                <span class="primary--text">Forma de Pagamento</span>
                <v-select
                  v-model="paymentFormId"
                  :items="paymentForm"
                  placeholder="Informe a forma de pagamento"
                  class="mt-1 custom-solo-selection"
                  solo
                  item-value="id"
                  item-text="descricao"
                  required
                  :rules="fieldRules"
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="mx-2 pt-0">
            <v-row>
              <v-col cols="12">
                <v-btn
                  color="primary"
                  block
                  dark
                  :disabled="!valid"
                  @click="validate"
                >
                  COMFIRMAR
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>

<script>
import Helpers from "@/mixins";
import BackArrow from "../../../components/BackArrow/index.vue";
export default {
  mixins: [Helpers],
  props: ["id"],
  data() {
    return {
      show: false,
      loading: false,
      message: {
        snacshow: false,
        text: "",
        type: "",
        mode: "",
        timeout: 2000,
      },
      cod_encomenda: "",
      search: "",
      agencia: [],
      data: [],
      motoristas: [],
      id_motorista: null,
      formaPagamentoShow: false,
      paymentForm: [],
      paymentFormId: null,
      fieldRules: [(v) => !!v || "Este campo é requerido"],
      valid: false,
    };
  },
  created() {
    this.loadAgency();
    this.loadDrivers();
    this.getpaymentForm();
  },
  mounted() {
    this.Helpers;
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.finalizeOrder(
          {
            id_encomenda: this.cod_encomenda,
            id_operador: this.$store.getters.getOperador,
            id_agencia: this.agencia.id,
            status: "ENTREGUE",
            id_formapagamento: this.paymentFormId,
          },
          true
        );
      } else {
        this.$store.dispatch("showMessage", {
          show: true,
          color: "warning darken-1",
          text: "Atencão um ou mais campos precisam ser preenchidos!",
        });
      }
    },
    getpaymentForm() {
      this.$http.get(`/formaspagamento`).then((r) => {
        this.paymentForm = r.data;
        this.$store.dispatch("activeLoader", false);
      });
    },
    loadDrivers() {
      this.loading = true;
      this.$http
        .get("/encomendas/motoristas")
        .then((r) => {
          this.motoristas = r.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          this.$store.dispatch("showMessage", {
            show: true,
            color: "error darken-1",
            text: e,
          });
        });
    },
    searchData() {
      this.loading = true;
      this.$http
        .get(
          `/encomendas/movimento/${this.search}/${this.$store.getters.getOpAgency}`
        )
        .then((r) => {
          this.data = r.data.dados;
          this.id_motorista = r.data.id_motorista;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          this.$store.dispatch("showMessage", {
            show: true,
            color: "error darken-1",
            text: e,
          });
        });
    },
    sendPacket(dados) {
      this.$store.dispatch("activeLoader", true);
      const newData = { ...dados };
      newData.id_motorista = this.id_motorista;
      this.$http
        .post(`/encomendas/enviar`, newData)
        .then((r) => {
          this.data = r.data;
          this.searchData();
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.$store.dispatch("showMessage", {
            show: true,
            color: "error darken-1",
            text: e,
          });
        });
    },
    movementOrder(dados) {
      this.$store.dispatch("activeLoader", true);
      const newData = { ...dados };
      newData.id_motorista = this.id_motorista;
      this.$http
        .post(`/encomendas/parada`, newData)
        .then((r) => {
          this.data = r.data;
          this.searchData();
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.$store.dispatch("showMessage", {
            show: true,
            color: "error darken-1",
            text: e,
          });
        });
    },
    finalizeOrder(data, paid = null) {
      this.$store.dispatch("activeLoader", true);
      data.paid = paid;
      const dados = {
        ...data,
        id_operador: this.$store.getters.getOperador,
        id_motorista: this.id_motorista,
      };
      this.$http
        .post(`/encomendas/finalize`, { dados })
        .then((r) => {
          this.data = r.data;
          this.show = false;
          this.formaPagamentoShow = false;
          this.searchData();
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.show = false;
          this.$store.dispatch("showMessage", {
            show: true,
            color: "error darken-1",
            text: e,
          });
        });
    },
    loadAgency() {
      this.$http
        .get(`/pdv/agencias/${this.$store.getters.getOpAgency}`)
        .then((r) => {
          this.agencia = r.data[0];
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.$store.dispatch("showMessage", {
            show: true,
            color: "error darken-1",
            text: e,
          });
        });
    },
  },
  components: { BackArrow },
};
</script>

<style src="./style.vue"></style>
